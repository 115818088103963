<template>
  <div class="row">
    <div class="col-12">
      <c-card title="검토정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- <c-btn
              v-if="!disabled"
              :url="completeUrl"
              :isSubmit="isComplete"
              :param="data"
              mappingType="PUT"
              label="검토승인"
              icon="check"
              @beforeAction="completeData"
              @btnCallback="completeCallback" 
            /> -->
            <c-btn
              v-if="!disabled"
              :url="updateUrl"
              :isSubmit="isSave"
              :param="data"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-field
              :editable="editable"
              :disabled="disabled"
              label="검토자"
              name="examineUserId"
              v-model="data.examineUserId">
            </c-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :editable="editable"
              :disabled="disabled"
              default="today"
              label="검토일"
              name="examineDate"
              v-model="data.examineDate"
            />
          </div>
          <div class="col-md-12 col-lg-12">
            <c-textarea
              :disabled="disabled"
              :editable="editable"
              label="검토의견"
              :rows="8"
              name="examineOpinion"
              v-model="data.examineOpinion">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
import selectConfig from '../../../../js/selectConfig';
export default {
  name: 'near-degree-damage',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      data: {
        examineOpinion: '', 
        examineDate: '',
        examineUserId: '',
        accidentStatusCd: '',
        chgUserId: '',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      updateUrl: '',
      isSave: false,
      completeUrl: '',
      isComplete: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.editable && Boolean(this.tabParam.accidentStatusCd) && this.data.accidentStatusCd !== 'ISNC000001' 
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.data.examineUserId = this.$store.getters.user.userId
      this.data.examineDate = this.$comm.getToday();
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.near.examine.get.url;
      this.updateUrl = transactionConfig.sop.iim.accident.near.examine.update.url;
      this.completeUrl = transactionConfig.sop.iim.accident.near.examine.complete.url;
      // code setting
      this.getDetail();
    },
    getDetail() {
      if (this.tabParam.iimAccidentId) {
        this.$http.url = this.$format(this.getUrl, this.tabParam.iimAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
        },);
      }
    },
    saveData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.chgUserId = this.$store.getters.user.userId
          
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    completeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '검토승인하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.chgUserId = this.$store.getters.user.userId
          
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>
